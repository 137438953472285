<template>
    <div class="row">
        <div class="col-12">
            <router-link class="btn btn-light-primary text-primary fs-3 mb-3" to="/branch/item/rate/">
                <i class="ti ti-chevron-left"></i>
            </router-link>
        </div>
    </div>
    <form ref="form" @submit.prevent="submitForm">
        <div class="mb-3">
            <label for="rateFromDate">Start</label>
            <input v-model="rateForm.fromDate" id="rateFromDate" class="form-control" type="date" />
        </div>
        <div class="row mb-3">
            <div class="col-5">
                <label for="rateAdultPrice">Adult Price</label>
                <input v-model="rateForm.adult.price" type="number" class="form-control" id="rateAdultPrice">
            </div>
            <div class="col-2">
                <label for="rateAdultSign">Sign</label>
                <select class="form-select" name="rateAdultSign" id="rateAdultSign" v-model="rateForm.adult.sign">
                    <option value=">="> Greater than </option>
                    <option value="<="> Less or equal </option>
                </select>
            </div>
            <div class="col-5">
                <label for="rateAdultAge">Adult Age</label>
                <input v-model="rateForm.adult.age" type="text" class="form-control" id="rateAdultAge">
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-5">
                <label for="rateChildPrice">Child Price</label>
                <input v-model="rateForm.child.price" type="number" class="form-control" id="rateAChildrice">
            </div>
            <div class="col-2">
                <label for="rateChildSign">Sign</label>
                <select class="form-select" name="rateChildSign" id="rateChildSign" v-model="rateForm.child.sign">
                    <option value=">="> Greater than </option>
                    <option value="<="> Less or equal </option>
                </select>
            </div>
            <div class="col-5">
                <label for="rateChildAge">Child Age</label>
                <input v-model="rateForm.child.age" type="text" class="form-control" id="rateChildAge">
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-5">
                <label for="rateInfantPrice">Infant Price</label>
                <input v-model="rateForm.infant.price" type="number" class="form-control" id="rateInfantdrice">
            </div>
            <div class="col-2">
                <label for="rateInfantSign">Sign</label>
                <select class="form-select" name="rateInfantSign" id="rateInfantSign" v-model="rateForm.infant.sign">
                    <option value=">="> Greater than </option>
                    <option value="<="> Less or equal </option>
                </select>
            </div>
            <div class="col-5">
                <label for="rateInfantAge">Infant Age</label>
                <input v-model="rateForm.infant.age" type="text" class="form-control" id="ratInfantdAge">
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-5">
                <label for="rateOldPrice">Elder Price</label>
                <input v-model="rateForm.old.price" type="number" class="form-control" id="rateAOldrice">
            </div>
            <div class="col-2">
                <label for="rateOldSign">Sign</label>
                <select class="form-select" name="rateOldSign" id="rateOldSign" v-model="rateForm.old.sign">
                    <option value=">="> Greater than </option>
                    <option value="<="> Less or equal </option>
                </select>
            </div>
            <div class="col-5">
                <label for="rateOldAge">Elder Age</label>
                <input v-model="rateForm.old.age" type="text" class="form-control" id="rateOldAge">
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-end">
                <button type="submit" class="btn btn-success">Save</button>
            </div>
        </div>
    </form>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { ref, reactive, onMounted } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification";
import { db } from '../../firebaseConfig'
import { doc, collection, addDoc, updateDoc } from 'firebase/firestore'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const router = useRouter()
        const { notify }  = useNotification()
        const isEdit = ref(false)

        const $loading = useLoading({
            color: '#539BFF'
        })

        const formInitialState = {
            fromDate: new Date().toISOString().slice(0,10),
            adult: {
                age: 0,
                price: 0,
                sign: '>='
            },
            child: {
                age: 0,
                price: 0,
                sign: '>='
            },
            infant: {
                age: 0,
                price: 0,
                sign: '>='
            },
            old: {
                age: 0,
                price: 0,
                sign: '>='
            },
        }

        const rateForm = reactive({ ...formInitialState })
        
        const submitForm = async () => {
            loader = $loading.show()
            addToCollection()
            .then(() => {
                loader.hide()
            })
        }

        function initForm() {
            Object.assign(rateForm, store.getters.rate.data)
            rateForm.fromDate = new Date(rateForm.fromDate.toDate()).toISOString().slice(0,10)
        }

        async function addToCollection() {
            var date = new Date(rateForm.fromDate)
            date.setHours(0,0,0,0)
            rateForm.fromDate = date

            if(isEdit.value) {
                let docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${store.getters.branch.data.id}/Menu/${store.getters.item.data.id}/Rate/${rateForm.id}`);
                delete rateForm.id
                
                await updateDoc(docRef, rateForm)
                .then(() => {
                    notify({
                        type: "success",
                        title: "It was updated correctly"
                    })
                    router.push('/branch/item/rate')
                })
                .catch((err) => console.log(err));
            } else {
                await addDoc(collection(db, `${ConfigMethods.MAIN_URL}/Branch/${store.getters.branch.data.id}/Menu/${store.getters.item.data.id}/Rate`), rateForm)
                .then((docRef) => {
                    console.log(docRef.id)
                    if (docRef.id) {
                        notify({
                            type: "success",
                            title: "It was saved correctly"
                        })
                        router.push('/branch/item/rate')
                    }
                })
                .catch((err) => console.log(err));
            }
        }

        onMounted(async () => {
            if( store.getters.branch.data == null || store.getters.item.data == null) {
                router.push('/branch/')
            } else {
                if( store.getters.rate.data != null ) {
                    initForm()
                    isEdit.value = true
                }
            }
        })

        return { rateForm, submitForm }
    },
    components: {
        
    }
}
</script>