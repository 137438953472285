import { createRouter, createWebHashHistory } from 'vue-router'
import SignInTemplate from '../templates/SignInTemplate.vue';
import DashboardTemplate from '../templates/DashboardTemplate.vue';


const routes = [
    {
        path: '/',
        redirect: '/branch',
        name: 'Dashboard',
        component: DashboardTemplate
    },
    {
        path: '/login',
        name: 'Login',
        component: SignInTemplate,
    },
    {
        path: '/register',
        name: 'Register',
        component: SignInTemplate,
    },
    {
        path: '/branch',
        name: 'BranchList',
        component: DashboardTemplate,
        children: [
            {
                path: 'new',
                name: 'BranchFormAdd',
                component: DashboardTemplate,
            },
            {
                path: 'edit',
                name: 'BranchFormEdit',
                component: DashboardTemplate,
            },
            {
                path: 'category',
                name: 'CategoryList',
                component: DashboardTemplate,
                children: [
                    {
                        path: 'new',
                        name: 'CategoryFormAdd',
                        component: DashboardTemplate,
                    },
                    {
                        path: 'edit',
                        name: 'CategoryFormEdit',
                        component: DashboardTemplate,
                    }
                ]
            },
            {
                path: 'item',
                name: 'ItemList',
                component: DashboardTemplate,
                children: [
                    {
                        path: 'new',
                        name: 'ItemFormAdd',
                        component: DashboardTemplate,
                    },
                    {
                        path: 'edit',
                        name: 'ItemFormEdit',
                        component: DashboardTemplate,
                    },
                    {
                        path: 'rate',
                        name: 'RateList',
                        component: DashboardTemplate,
                        children: [
                            {
                                path: 'new',
                                name: 'RateFormAdd',
                                component: DashboardTemplate,
                            },
                            {
                                path: 'edit',
                                name: 'RateFormEdit',
                                component: DashboardTemplate,
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '/site',
        name: 'SiteForm',
        component: DashboardTemplate
    },
    {
        path: '/profile',
        name: 'ProfileForm',
        component: DashboardTemplate
    },
    {
        path: '/settings',
        name: 'CompanyForm',
        component: DashboardTemplate
    },
    {
        path: '/rate',
        name: 'RateForm',
        component: DashboardTemplate
    },
    {
        path: '/reservation',
        name: 'ReservationList',
        component: DashboardTemplate,
        children: [
            // {
            //     path: 'new',
            //     name: 'ItemFormAdd',
            //     component: DashboardTemplate,
            // },
            {
                path: 'edit',
                name: 'ReservationFormEdit',
                component: DashboardTemplate,
            }
        ]
    },
    {
        path: '/language',
        name: 'LanguageForm',
        component: DashboardTemplate
    },
]


const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router 