<template>
  <form action="#" @submit.prevent="Login">
    <div class="mb-3">
      <label for="email" class="form-label">Username</label>
      <input type="email" class="form-control" id="email" aria-describedby="email" value required autofocus
        v-model="email" />
    </div>
    <div class="mb-4">
      <label for="password" class="form-label">Password</label>
      <input type="password" class="form-control" id="password" required v-model="password" />
    </div>
    <button type="submit" class="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2">Login</button>
    <div class="d-flex align-items-center justify-content-center">
      <router-link class="text-primary fw-bold ms-2" to="/register">Create an account</router-link>
    </div>
  </form>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: "LoginComponent",
  setup() {
    const email = ref('')
    const password = ref('')
    const error = ref(null)

    const store = useStore()
    const router = useRouter()

    const Login = async () => {
      await store.dispatch("logIn", { email: email.value, password: password.value }).then(() => {
        router.push('/branch/')
      }).catch(error => {
        console.log(error)
        store.dispatch('logOut')
      })
    }
    return { Login, email, password, error }
  }
};
</script>