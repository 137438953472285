<template>
    <div class="row">
        <div class="col-12">
            <router-link class="btn btn-light-primary text-primary fs-3 mb-3" to="/branch/category/">
                <i class="ti ti-chevron-left"></i>
            </router-link>
        </div>
    </div>
    <form ref="form" @submit.prevent="submitForm">
        <div class="row" v-if="languages">
            <div class="col-6" v-for="(language, index) in languages" :key="index">
                <div class="input-group mb-3">
                    <span class="input-group-text" :id="`categoryName${capitalize(language.iso)}`">{{ capitalize(language.iso) }}</span>
                    <input v-model="categoryForm.name[language.iso]" type="text" class="form-control" :id="`categoryName${capitalize(language.iso)}`" :aria-describedby="`categoryName${capitalize(language.iso)}`">
                </div>
            </div>
        </div>
        <div class="mb-3">
            <label for="categoryOrder">Order</label>
            <select v-model="categoryForm.order" class="form-select" name="categoryOrder" id="categoryOrder">
                <option value="null" disabled>Select option</option>
                <option v-for="i in 20" :key="i" :value="i">{{ i }}</option>
            </select>
        </div>
        <div class="mb-3 form-check">
            <input v-model="categoryForm.isActive" type="checkbox" class="form-check-input" id="categoryIsActive">
            <label class="form-check-label" for="categoryIsActive">Is Active</label>
        </div>
        <div class="mb-3 form-check">
            <input v-model="categoryForm.isVisible" type="checkbox" class="form-check-input" id="categoryIsVisible">
            <label class="form-check-label" for="categoryIsVisible">Is Visible</label>
        </div>
        <div class="row">
            <div class="col-12 text-end">
                <button type="submit" class="btn btn-success">Save</button>
            </div>
        </div>
    </form>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { ref, onMounted, reactive, computed } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification";
import { db } from '../../firebaseConfig'
import { doc, collection, addDoc, updateDoc } from 'firebase/firestore'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const router = useRouter()
        const { notify }  = useNotification()
        const isEdit = ref(false)
        var id = null

        const $loading = useLoading({
            color: '#539BFF'
        })
        
        const formInitialState = {
            name: {
                en: '',
                es: '',
            },
            order: 0,
            isActive: false,
            isVisible: false,
        }

        const categoryForm = reactive({ ...formInitialState })

        const submitForm = async () => {
            loader = $loading.show()
            addToCollection()
            .then(() => {
                loader.hide()
            })
        }

        const languages = computed(() => {
            return (store.getters.general.data) ? store.getters.general.data.language : []
        })

        function capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        function initForm() {
            Object.assign(categoryForm, store.getters.category.data)
        }

        // function resetForm() {
        //     Object.assign(categoryForm, ...branchInitialState)
        // }

        async function addToCollection() {
            if(isEdit.value) {
                let docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${id}/Category/${categoryForm.id}`);
                delete categoryForm.id
                
                await updateDoc(docRef, categoryForm)
                .then(() => {
                    notify({
                        type: "success",
                        title: "It was updated correctly"
                    })
                    router.push('/branch/category/')
                })
                .catch((err) => console.log(err));
            } else {
                await addDoc(collection(db, `${ConfigMethods.MAIN_URL}/Branch/${id}/Category`), categoryForm)
                .then((docRef) => {
                    console.log(docRef.id)
                    if (docRef.id) {
                        notify({
                            type: "success",
                            title: "It was saved correctly"
                        })
                        router.push('/branch/category/')
                    }
                })
                .catch((err) => console.log(err));
            }
        }

        onMounted(async () => {
            if( store.getters.branch.data == null ) {
                router.push('/branch/category/')
            } else {
                id = store.getters.branch.data.id
                if( store.getters.category.data != null ) {
                    isEdit.value = true
                    initForm()
                } else {
                    categoryForm.order = 1
                }
            }
        });

        return { categoryForm, languages, submitForm, capitalize  };
    },
    components: {
        
    }
}
</script>