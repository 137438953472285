<template>
    <div class="row">
        <div class="col-12 text-right">
            <router-link class="btn btn-primary btn-md" to="/branch/new">New Branch</router-link>
        </div>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col" class="text-end">Order</th>
                <th scope="col" class="text-center">Active</th>
                <th scope="col" class="text-center">Visible</th>
                <th scope="col" class="text-center">Transactionable</th>
                <th scope="col" class="text-center">Category</th>
                <th scope="col" class="text-center">Item</th>
                <th scope="col" class="text-center">Edit</th>
                <th scope="col" class="text-center">Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="branch in branches" :key="branch.id">
                <td class="align-middle fs-4">{{ branch.name.en }}</td>
                <td class="text-end align-middle fs-4">{{ branch.order }}</td>
                <td class="text-center align-middle fs-6">
                    <i class="ti" :class = "(branch.isActive) ? 'ti-check' : 'ti-x'"></i>
                </td>
                <td class="text-center align-middle fs-6">
                    <i class="ti" :class = "(branch.isVisible) ? 'ti-check' : 'ti-x'"></i>
                </td>
                <td class="text-center align-middle fs-6">
                    <i class="ti" :class = "(branch.isTransactionable) ? 'ti-check' : 'ti-x'"></i>
                </td>
                <td class="text-center align-middle fs-6">
                    <a href="#" @click.prevent="category(branch.id)" v-tooltip="'Open category'">
                        <i class="ti ti-eye"></i>
                    </a>
                </td>
                <td class="text-center align-middle fs-6">
                    <a href="javascript:void(0)" @click.prevent="item(branch.id)" v-tooltip="'Open item'">
                        <i class="ti ti-eye"></i>
                    </a>
                </td>
                <td class="text-center align-middle fs-6">
                    <a href="javascript:void(0)" @click.prevent="edit(branch.id)" v-tooltip="'Edit'">
                        <i class="ti ti-edit"></i>
                    </a>
                </td>
                <td class="text-center align-middle fs-6">
                    <a href="javascript:void(0)" v-tooltip="'Delete'" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="setIdToDelete(branch.id)">
                        <i class="ti ti-trash"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure about deleting the information?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-danger" data-bs-dismiss="modal" @click="deleteBranch()">Yes</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { useNotification } from "@kyvg/vue3-notification"
import { db, storage } from '../../firebaseConfig'
import { collection, query, orderBy, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import { ref as storageRef, deleteObject } from 'firebase/storage'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        var idToDelete = null
        const router = useRouter()
        const store = useStore()
        const { notify }  = useNotification()
        const branches = ref([])

        const $loading = useLoading({
            color: '#539BFF'
        })
        
        async function deleteStore() {
            await store.dispatch('deleteBranch')
        }

        const edit = (id) => {
            saveBranch(id)
            router.push('/branch/edit')
        }

        const category = (id) => {
            saveBranch(id)
            router.push('/branch/category')
        }

        const item = (id) => {
            saveBranch(id)
            router.push('/branch/item')
        }

        async function saveBranch(id) {
            let data = branches.value.find(x => x.id === id)
            await store.dispatch('addBranch', data)
        }

        function loadData() {
            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/Branch`), orderBy('order'));
            onSnapshot(q, (querySnapshot) => {
                branches.value = []
                querySnapshot.forEach((doc) => {
                    branches.value.push({ id: doc.id, ...doc.data() })
                });
            })
        }

        function setIdToDelete(id) {
            idToDelete = id
        }

        function deleteBranch() {
            loader = $loading.show()

            const deleteDocument = async (docRef) => {
                await deleteDoc(docRef)
            }

            clearCollection(`${ConfigMethods.MAIN_URL}/Branch/${idToDelete}/Category`)
            .then(() => {
                clearCollection(`${ConfigMethods.MAIN_URL}/Branch/${idToDelete}/Menu`)
                .then(() => {
                    let data = branches.value.find(x => x.id === idToDelete)
                    let images = []
                    images.push(data.logo)

                    deleteImagesInStorage(images)
                    .then(() => {
                        const docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch`, idToDelete)
                        deleteDocument(docRef)
                        .then(() => {
                            loader.hide()
                            notify({
                                type: "success",
                                title: "It was deleted correctly"
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    })
                })
                .catch(error => {
                    console.log(error);
                })
            })
            .catch(error => {
                console.log(error);
            })
        }

        async function deleteImagesInStorage(data) {
            const deleteFile = async (storageReference) => {
                await deleteObject(storageReference)
            }

            try {
                data.forEach((file) => {
                    const storageReference = storageRef(storage, file)
                    deleteFile(storageReference)
                })
            } catch (error) {
                console.log(error);
            }
        }

        async function clearCollection(path) {
            const deleteDocument = async (docRef) => {
                await deleteDoc(docRef)
            }

            const q = query(collection(db, path));
            onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((document) => {
                    let data = document.data()
                    let images = []

                    if( data.album !== undefined ) {
                        images = data.album
                    } else if( data.logo !== undefined ) {
                        images.push(data.logo)
                    }

                    if(images.length > 0 ) {
                        deleteImagesInStorage(images)
                        .then(() => {
                            const docRef = doc(db, path, document.id)
                            deleteDocument(docRef)
                        })
                        .catch(error => {
                            console.log(error);
                        })
                    } else {
                        const docRef = doc(db, path, document.id)
                        deleteDocument(docRef)
                    }
                })
            })
        }
        
        onMounted(() => {
            let client = localStorage.getItem('client')
            if(client) {
                loadData()
                deleteStore()
            }
        })

        return { branches, category, item, edit, setIdToDelete, deleteBranch }
    }
}
</script>