<template>
    <!-- Sidebar Start -->
    <aside class="left-sidebar">
        <!-- Sidebar scroll-->
        <div>
            <div class="brand-logo d-flex align-items-center justify-content-between">
                <router-link class="text-nowrap logo-img" to="/branch" v-if="user">
                    <img v-if="user.legalInformation.logo" :src="user.legalInformation.logo" width="180" height="27">
                    <img v-else :src="require('@/assets/images/logos/dark-logo.svg')" width="180">
                </router-link>
                <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
                    <i class="ti ti-x fs-8"></i>
                </div>
            </div>
            <!-- Sidebar navigation-->
            <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
                <ul id="sidebarnav">
                    <li class="nav-small-cap">
                        <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                        <span class="hide-menu" v-if="user">{{ (user.legalInformation.commercialName) ? user.legalInformation.commercialName : "CELLF ORDER" }}</span>
                    </li>
                    <li class="sidebar-item">
                        <router-link activeClass="active" class="sidebar-link" to="/branch">
                            <span>
                                <i class="ti ti-building"></i>
                            </span>
                            <span class="hide-menu">Branches</span>
                        </router-link>
                    </li>
                    <li class="sidebar-item">
                        <router-link activeClass="active" class="sidebar-link" to="/reservation">
                            <span>
                                <i class="ti ti-file-info"></i>
                            </span>
                            <span class="hide-menu">Reservations</span>
                        </router-link>
                    </li>
                    <li class="nav-small-cap">
                        <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                        <span class="hide-menu">CATALOGS</span>
                    </li>
                    <li class="sidebar-item">
                        <router-link exactActiveClass="active" class="sidebar-link" to="/site">
                            <span>
                                <i class="ti ti-sitemap"></i>
                            </span>
                            <span class="hide-menu">Site</span>
                        </router-link>
                    </li>
                    <li class="sidebar-item">
                        <router-link exactActiveClass="active" class="sidebar-link" to="/language">
                            <span>
                                <i class="ti ti-world"></i>
                            </span>
                            <span class="hide-menu">Language</span>
                        </router-link>
                    </li>
                    <li class="nav-small-cap">
                        <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                        <span class="hide-menu">COMPANY</span>
                    </li>
                    <li class="sidebar-item">
                        <router-link exactActiveClass="active" class="sidebar-link" to="/settings">
                            <span>
                                <i class="ti ti-settings"></i>
                            </span>
                            <span class="hide-menu">Settings</span>
                        </router-link>
                    </li>
                </ul>
            </nav>
            <!-- End Sidebar navigation -->
        </div>
        <!-- End Sidebar scroll-->
    </aside>
    <!--  Sidebar End -->
    <!--  Main wrapper -->
    <div class="body-wrapper">
        <!--  Header Start -->
        <header class="app-header">
            <nav class="navbar navbar-expand-lg navbar-light">
                <ul class="navbar-nav">
                    <li class="nav-item d-block d-xl-none">
                        <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                            <i class="ti ti-menu-2"></i>
                        </a>
                    </li>
                </ul>
                <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
                    <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                        <li class="nav-item dropdown" v-if="user">
                            <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <img v-if="user.representative.photo" :src="user.representative.photo" width="35" height="35" class="rounded-circle">
                                <img v-else :src="require('@/assets/images/profile/user-1.jpg')" width="35" height="35" class="rounded-circle">
                            </a>
                            <div v-if="user" class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                <div class="message-body">
                                    <router-link class="dropdown-item" to="/profile">
                                        <p class="m-0 text-center">{{ user.representative.name }}</p>
                                        <p class="m-0 text-center">{{ user.representative.email }}</p>
                                    </router-link>
                                    <a href="javascript:void(0)" @click.prevent="signOut()" class="btn btn-outline-primary mx-3 mt-2 d-block">Logout</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
        <!--  Header End -->
        <div class="container-fluid">
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb text-capitalize">
                        <li class="breadcrumb-item" v-for="(path, index) in paths" :key="index">
                            {{ path }}
                        </li>
                    </ol>
                </nav>
                <div class="card">
                    <div class="card-body">
                        <BranchList v-if="isBranchList" />
                        <BranchForm v-if="isBranchForm" />
                        <CategoryList v-if="isCategoryList" />
                        <CategoryForm v-if="isCategoryForm" />
                        <ItemList v-if="isItemList" />
                        <ItemForm v-if="isItemForm" />
                        <RateList v-if="isRateList" />
                        <RateForm v-if="isRateForm" />
                        <ReservationList v-if="isReservationList" />
                        <ReservationForm v-if="isReservationForm" />
                        <SiteForm v-if="isSite" />
                        <ProfileForm v-if="isProfile" />
                        <CompanyForm v-if="isCompany" />
                        <LanguageForm v-if="isLanguage" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, computed, ref, watch } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { auth } from '../firebaseConfig'
import BranchList from '../views/branch/BranchList.vue';
import BranchForm from '../views/branch/BranchForm.vue';
import CategoryList from '../views/category/CategoryList.vue';
import CategoryForm from '../views/category/CategoryForm.vue';
import ItemList from '../views/item/ItemList.vue';
import ItemForm from '../views/item/ItemForm.vue';
import RateList from '../views/rate/RateList.vue';
import RateForm from '../views/rate/RateForm.vue';
import ReservationList from '../views/reservation/ReservationList.vue';
import ReservationForm from '../views/reservation/ReservationForm.vue';
import SiteForm from '../views/settings/SiteForm.vue';
import ProfileForm from '../views/settings/ProfileForm.vue';
import CompanyForm from '../views/settings/CompanyForm.vue';
import LanguageForm from '../views/settings/LanguageForm.vue';

export default {
    name: "DashboardTemplate",
    computed: {
        isBranchList() {
            return this.$route.name === 'BranchList'
        },
        isBranchForm() {
            return this.$route.name === 'BranchFormAdd' || this.$route.name === 'BranchFormEdit'
        },
        isCategoryList() {
            return this.$route.name === 'CategoryList'
        },
        isCategoryForm() {
            return this.$route.name === 'CategoryFormAdd' | this.$route.name === 'CategoryFormEdit'
        },
        isItemList() {
            return this.$route.name === 'ItemList'
        },
        isItemForm() {
            return this.$route.name === 'ItemFormAdd' | this.$route.name === 'ItemFormEdit'
        },
        isRateList() {
            return this.$route.name === 'RateList'
        },
        isRateForm() {
            return this.$route.name === 'RateFormAdd' | this.$route.name === 'RateFormEdit'
        },
        isReservationList() {
            return this.$route.name === 'ReservationList'
        },
        isReservationForm() {
            return this.$route.name === 'ReservationFormAdd' | this.$route.name === 'ReservationFormEdit'
        },
        isSite() {
            return this.$route.name === 'SiteForm'
        },
        isProfile() {
            return this.$route.name === 'ProfileForm'
        },
        isCompany() {
            return this.$route.name === 'CompanyForm'
        },
        isLanguage() {
            return this.$route.name === 'LanguageForm'
        },
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const paths = ref([])

        const getBreadCrumbs = () => {
            paths.value = router.currentRoute.value.path.split('/')
            paths.value = paths.value.filter((val) => val != "")
            paths.value.forEach((path, index) => {
                switch(path) {
                    case 'branch': 
                        (paths.value.length > 1 && store.getters.branch.data) ? paths.value.splice(index+1, 0, store.getters.branch.data.name.en) : null
                        break
                    case 'category': 
                        (store.getters.category.data) ? paths.value.splice(index+1, 0, store.getters.category.data.name.en) : null
                        break
                    case 'item': 
                        (store.getters.item.data) ? paths.value.splice(index+1, 0, store.getters.item.data.name.en) : null
                        break
                }
            })
            
        }

        auth.onAuthStateChanged(async user => {
            await store.dispatch("fetchUser", user).then(() => {
                console.log("El login fue correcto")
            }).catch(error => {
                console.log(error)
                store.dispatch('logOut')
                router.push('/login')
            })
        })

        const user = computed(() => {
            return store.getters.user.data
        })

        const signOut = async () => {
            await store.dispatch('logOut')
            router.push('/login')
        }

        watch(() => router.currentRoute.value.fullPath,
            async () => {
                getBreadCrumbs()
            }
        )

        onMounted(() => {
            getBreadCrumbs()
            if(!store.getters.user) {
                router.push('/login/')
            } else {
                // router.push('/branch/')
            }
        })

        return { user, signOut, paths }
    },
    components: {
        BranchList,
        BranchForm,
        CategoryList,
        CategoryForm,
        ItemList,
        ItemForm,
        RateList,
        RateForm,
        ReservationList,
        ReservationForm,
        SiteForm,
        ProfileForm,
        CompanyForm,
        LanguageForm
    }
};
</script>