<template>
    <div class="row">
        <div class="col-12 text-right">
            <router-link class="btn btn-light-primary text-primary fs-3" to="/branch">
                <i class="ti ti-chevron-left"></i>
            </router-link>
            <router-link class="btn btn-primary mx-1" to="/branch/item/new">New Item</router-link>
        </div>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col" class="text-end">Order</th>
                <th scope="col" class="text-center">Active</th>
                <th scope="col" class="text-center">Visible</th>
                <th scope="col" class="text-center">Rate</th>
                <th scope="col" class="text-center">Edit</th>
                <th scope="col" class="text-center">Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in items" :key="item.id">
                <td class="align-middle fs-4">{{ item.name.en }}</td>
                <td class="text-end align-middle fs-4">{{ item.order }}</td>
                <td class="text-center align-middle fs-6">
                    <i class="ti" :class = "(item.isActive) ? 'ti-check' : 'ti-x'"></i>
                </td>
                <td class="text-center align-middle fs-6">
                    <i class="ti" :class = "(item.isVisible) ? 'ti-check' : 'ti-x'"></i>
                </td>
                <td class="text-center align-middle fs-6">
                    <a href="javascript:void(0)" @click.prevent="rate(item.id)" v-tooltip="'Open rate'">
                        <i class="ti ti-eye"></i>
                    </a>
                </td>
                <td class="text-center align-middle fs-6">
                    <a href="javascript:void(0)" @click.prevent="edit(item.id)" v-tooltip="'Edit'">
                        <i class="ti ti-edit"></i>
                    </a>
                </td>
                <td class="text-center align-middle fs-6">
                    <a href="javascript:void(0)" v-tooltip="'Delete'" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="setIdToDelete(item.id)">
                        <i class="ti ti-trash"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure about deleting the information?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-danger" data-bs-dismiss="modal" @click="deleteItem()">Yes</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { useNotification } from "@kyvg/vue3-notification"
import { db, storage } from '../../firebaseConfig'
import { collection, query, orderBy, onSnapshot, doc, deleteDoc } from "firebase/firestore"
import { ref as storageRef, deleteObject } from 'firebase/storage'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        var idToDelete = null
        const router = useRouter()
        const store = useStore()
        const { notify }  = useNotification()
        const items = ref([])

        const $loading = useLoading({
            color: '#539BFF'
        })

        function setIdToDelete(id) {
            idToDelete = id
        }

        async function deleteItem() {
            loader = $loading.show()
            deleteImagesInStorage()
            .then(() => {
                const docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${store.getters.branch.data.id}/Menu`, idToDelete);
                deleteDoc(docRef)
                .then(() => {
                    loader.hide()
                    notify({
                        type: "success",
                        title: "It was deleted correctly"
                    })
                })
                .catch(error => {
                    console.log(error);
                })
            })
            .catch(error => {
                console.log(error);
            })
        }

        async function deleteImagesInStorage() {
            let data = items.value.find(x => x.id === idToDelete)

            const deleteFile = async (storageReference) => {
                await deleteObject(storageReference)
            }

            try {
                data.album.forEach((file) => {
                    const storageReference = storageRef(storage, file)
                    deleteFile(storageReference)
                })
            } catch (error) {
                console.log(error);
            }
        }
        
        async function deleteStore() {
            await store.dispatch('deleteItem')
        }

        const edit = (id) => {
            saveMenu(id)
            router.push('/branch/item/edit')
        }

        const rate = (id) => {
            saveMenu(id)
            router.push('/branch/item/rate/')
        }

        async function saveMenu(id) {
            let data = items.value.find(x => x.id === id)
            await store.dispatch('addItem', data)
        }

        function loadData() {
            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/Branch/${store.getters.branch.data.id}/Menu`), orderBy('order'))
            onSnapshot(q, (querySnapshot) => {
                items.value = []
                querySnapshot.forEach((doc) => {
                    items.value.push({ id: doc.id, ...doc.data() })
                });
            });
        }
        
        onMounted(() => {
            if( store.getters.branch.data == null ) {
                router.push('/branch/')
            } else {
                loadData()
                deleteStore()
            }
        })

        return { items, rate, edit, setIdToDelete, deleteItem };
    }
}
</script>