<template>
    <div class="row">
        <div class="col-12">
            <router-link class="btn btn-light-primary text-primary fs-3 mb-3" to="/branch">
                <i class="ti ti-chevron-left"></i>
            </router-link>
        </div>
    </div>
    <div class="row">
        <p v-if="errors.length" class="alert alert-danger">
            <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
            <ul class="m-0 list-group">
                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
        </p>
        <form ref="form" @submit.prevent="submitForm" novalidate>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="false">Name</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="true">Overview</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="simple-tab-2" data-bs-toggle="tab" href="#simple-tabpanel-2" role="tab" aria-controls="simple-tabpanel-2" aria-selected="false">Logo</a>
                </li>
            </ul>
            <div class="tab-content pt-5" id="tab-content">
                <div class="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">
                    <div class="row" v-if="languages">
                        <div class="col-6" v-for="(language, index) in languages" :key="index">
                            <div class="input-group mb-3">
                                <span class="input-group-text text-uppercase" :id="`branchName${capitalize(language.iso)}`">{{ language.iso }}</span>
                                <input v-model="branchForm.name[language.iso]" type="text" class="form-control" :id="`branchName${capitalize(language.iso)}`" :aria-describedby="`branchName${capitalize(language.iso)}`">
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="branchOrder">Order</label>
                        <select v-model="branchForm.order" class="form-select" name="branchOrder" id="branchOrder">
                            <option value="null" disabled>Select option</option>
                            <option v-for="i in 20" :key="i" :value="i">{{ i }}</option>
                        </select>
                    </div>
                    <div class="mb-3 form-check">
                        <input v-model="branchForm.isActive" type="checkbox" class="form-check-input" id="branchIsActive">
                        <label class="form-check-label" for="branchIsActive">Is Active</label>
                    </div>
                    <div class="mb-3 form-check">
                        <input v-model="branchForm.isVisible" type="checkbox" class="form-check-input" id="branchIsVisible">
                        <label class="form-check-label" for="branchIsVisible">Is Visible</label>
                    </div>
                    <div class="mb-3 form-check">
                        <input v-model="branchForm.isTransactionable" type="checkbox" class="form-check-input"
                            id="branchIsTransactionable">
                        <label class="form-check-label" for="branchIsTransactionable">Is Transactionable</label>
                    </div>
                </div>
                <div class="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">
                    <div class="row" v-if="languages">
                        <div class="col-6" v-for="(language, index) in languages" :key="index">
                            <div class="input-group mb-3">
                                <label class="text-uppercase" :for="`branchExplanation${capitalize(language.iso)}`">{{ language.iso }}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <editor v-model="branchForm.explanation[language.iso]" :id="`branchExplanation${capitalize(language.iso)}`"
                                    api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="simple-tabpanel-2" role="tabpanel" aria-labelledby="simple-tab-2">
                    <div class="input-group mb-3">
                        <input @change="handleFileChange" type="file" accept="image/*" class="form-control" id="inputGroupFile" aria-describedby="inputGroupFile" aria-label="Upload">
                    </div>
                    <div class="mb-3">
                        <div class="upload__img-wrap">
                            <div class="upload__img-box" v-for="(image, index) in images" :key="index">
                                <div :style="`background-image: url('${image.url}')`" class="img-bg">
                                    <div @click="deleteImage(image, index)" class="upload__img-close"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-end">
                    <button type="submit" class="btn btn-success">Save</button>
                </div>
            </div>
        </form>
    </div>     
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { ref, onMounted, computed, reactive } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification"
import { db, storage } from '../../firebaseConfig'
import { doc, collection, addDoc, updateDoc } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import Editor from '@tinymce/tinymce-vue'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const router = useRouter()
        const { notify }  = useNotification()
        const isEdit = ref(false)
        const multipleImages = ref(false)
        const images = ref([])
        const deletedImages = ref([])
        const files = ref([])
        const urls = ref([])
        const errors = ref([])
        // const languageRef = ref(null)
        // const languages = ref([])

        const $loading = useLoading({
            color: '#539BFF'
        })
        
        const formInitialState = {
            logo: '',
            name: {
                
            },
            explanation: {
                
            },
            order: 0,
            isActive: false,
            isVisible: false,
            isTransactionable: false
        }

        const branchForm = reactive({ ...formInitialState })

        // const back = () => {
        //     router.push('/branch/')
        // }

        const handleFileChange = (e) => {
            if (e.target.files) {
                if( !multipleImages.value ) {
                    if(images.value.length > 0) {
                        deleteImage(images.value[0], 0)
                    }
                    images.value = []
                    files.value = []
                }

                var reader  = new FileReader()
                for (const file of e.target.files) {
                    files.value.push(file)
                    reader.onloadend = function () {
                        let object = {
                            name: file.name,
                            url: reader.result
                        }
                        images.value.push(object)
                    }
                    reader.readAsDataURL(file)
                }
            }
        }

        const deleteImage = (file, index) => {
            if (isURL(file.url)) {
                deletedImages.value.push(file)
            } else {
                for (var i = 0; i < files.value.length; i++) {
                    if (files.value[i].name === file.name) {
                        files.value.splice(i, 1);
                        break;
                    }
                }
            }

            images.value.splice(index, 1);
        }

        const submitForm = async () => {
            if( isValidForm() ) {
                loader = $loading.show()
                uploadImagesInStorage()
                .then(() => {
                    addToCollection()
                    .then(() => {
                        loader.hide()
                        deleteImagesInStorage()
                    })
                })
            }
        }

        const languages = computed(() => {
            return (store.getters.general.data) ? store.getters.general.data.language : []
        })

        function capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        function isValidForm() {
            let error = false

            languages.value.forEach((language) => {
                if (!branchForm.name[language.iso]) {
                    errors.value.push(`Name ${capitalize(language.iso)} can't be empty`)
                    error = true
                }
            })

            return !error
        }

        function initForm() {
            Object.assign(branchForm, store.getters.branch.data)
            initImages()
        }

        function initImages() {
            if(branchForm.logo != "" && branchForm.logo != null && branchForm.logo != undefined) {
                let object = {
                    name: branchForm.logo,
                    url: branchForm.logo
                }
                images.value.push(object)
            }
        }

        function isURL(string) {
            let url
            try {
                url = new URL(string)
            } catch (_) {
                return false;  
            }

            return url.protocol === "http:" || url.protocol === "https:";
        }

        function getRandomFileName() {
            var timestamp = new Date().toISOString().replace(/[-:.]/g,"")
            var random = ("" + Math.random()).substring(2, 8);
            var random_number = timestamp+random
            return random_number
        }

        function deleteImagesInStorage() {
            const deleteFile = async (storageReference) => {
                deleteObject(storageReference)
            }

            try {
                deletedImages.value.forEach((file) => {
                    const storageReference = storageRef(storage, file.url)
                    deleteFile(storageReference)
                })
            } catch (error) {
                console.log(error);
            }
        }

        async function uploadImagesInStorage() {
            const promises = []
            const uploadFile = async (storageReference, file) => {
                await uploadBytes(storageReference, file)
                await getDownloadURL(storageReference)
                .then((url) => {
                    urls.value.push(url)
                })
            }

            try {
                files.value.forEach((file) => {
                    let name = getRandomFileName()
                    const storageReference = storageRef(storage, 'images/' + name)
                    promises.push(uploadFile(storageReference, file))
                })
            } catch (error) {
                console.log(error);
            }
            
            await Promise.all(promises)
        }

        async function addToCollection() {
            if(urls.value.length == 0 && deletedImages.value.length == 0) {
                if(images.value.length > 0) {
                    branchForm.logo = images.value[0].url
                } else {
                    branchForm.logo = ""    
                }
            } else if(urls.value.length == 0 && deletedImages.value.length > 0) {
                branchForm.logo = ""
            } else {
                branchForm.logo = urls.value[0]
            }
            
            if(isEdit.value) {
                let docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${branchForm.id}`);
                delete branchForm.id

                await updateDoc(docRef, branchForm)
                .then(() => {
                    notify({
                        type: "success",
                        title: "It was updated correctly"
                    })
                    router.push('/branch/')
                })
                .catch((err) => console.log(err));
            } else {
                await addDoc(collection(db, `${ConfigMethods.MAIN_URL}/Branch`), branchForm)
                .then((docRef) => {
                    console.log(docRef.id)
                    if (docRef.id) {
                        notify({
                            type: "success",
                            title: "It was saved correctly"
                        })
                        router.push('/branch/')
                    }
                })
                .catch((err) => console.log(err));
            }
        }

        onMounted(async () => {
            if( store.getters.branch.data != null ) {
                isEdit.value = true
                initForm()
            } else {
                branchForm.order = 1
            }
        })

        return { languages, branchForm, images, capitalize, handleFileChange, deleteImage, submitForm, errors  };
    },
    components: {
        'editor': Editor
    }
}
</script>