import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/index'
import store from './store'
import Notifications from '@kyvg/vue3-notification'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

createApp(App).use(router).use(store).use(Notifications).use(LoadingPlugin).use(FloatingVue).mount('#app')