<template>
    <table class="table">
        <thead>
            <tr>
                <th scope="col" class="">Code</th>
                <th scope="col" class="">Name</th>
                <th scope="col" class="">Phone</th>
                <th scope="col" class="">Email</th>
                <th scope="col" class="">Estatus</th>
                <th scope="col" class="text-end">Total</th>
                <th scope="col" class="">Edit</th>
                <th scope="col" class="">Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="reservation in reservations" :key="reservation.id">
                <td class="align-middle">{{ reservation.code }}</td>
                <td class="align-middle">{{ reservation.contact.firstName }} {{ reservation.contact.lastName }}</td>
                <td class="align-middle">{{ reservation.contact.phone }}</td>
                <td class="align-middle">{{ reservation.contact.email }}</td>
                <td class="align-middle">{{ reservation.status }}</td>
                <td class="text-end align-middle">${{ reservation.detail.subtotal }}</td>
                <td class="align-middle fs-6">
                    <a href="javascript:void(0)" @click.prevent="edit(reservation.id)" v-tooltip="'Edit'">
                        <i class="ti ti-edit"></i>
                    </a>
                </td>
                <td class="align-middle fs-6">
                    <a href="javascript:void(0)" v-tooltip="'Delete'" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="setIdToDelete(reservation.id)">
                        <i class="ti ti-trash"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure about deleting the information?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-danger" data-bs-dismiss="modal" @click="deleteReservation()">Yes</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { useNotification } from "@kyvg/vue3-notification"
import { db } from '../../firebaseConfig'
import { collection, query, onSnapshot, doc, getDoc, deleteDoc, orderBy } from "firebase/firestore";
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        var idToDelete = null
        const router = useRouter()
        const store = useStore()
        const { notify }  = useNotification()
        const reservations = ref([])

        const $loading = useLoading({
            color: '#539BFF'
        })
        
        async function deleteStore() {
            await store.dispatch('deleteReservation')
        }

        const edit = (id) => {
            saveReservation(id)
            router.push('/reservation/edit')
        }

        async function saveReservation(id) {
            let data = reservations.value.find(x => x.id === id)
            let obj = {
                reservation: data
            }
            await store.dispatch('addReservation', obj)
        }

        function loadData() {
            const getProduct = async (docReference) => {
                const getDocByReference = await getDoc(docReference)
                return getDocByReference.data()
            }

            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/Reservation`), orderBy('created'));
            onSnapshot(q, (querySnapshot) => {
                reservations.value = []
                querySnapshot.forEach(async (doc) => {
                    let data = doc.data()
                    data.product = await getProduct(data.detail.product.reference)
                    reservations.value.push({ id: doc.id, ...data })
                })
            })
        }

        function setIdToDelete(id) {
            idToDelete = id
        }

        function deleteReservation() {
            loader = $loading.show()
            const docRef = doc(db, `${ConfigMethods.MAIN_URL}/Reservation`, idToDelete);
            deleteDoc(docRef)
            .then(() => {
                loader.hide()
                notify({
                    type: "success",
                    title: "It was deleted correctly"
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
        
        onMounted(() => {
            let client = localStorage.getItem('client')
            if(client) {
                loadData()
                deleteStore()
            }
        })

        return { reservations, edit, setIdToDelete, deleteReservation }
    }
}
</script>