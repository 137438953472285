import { createStore } from 'vuex'
import { db, auth } from './firebaseConfig'
import { doc, setDoc, getDoc, Timestamp } from 'firebase/firestore'
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, signOut } from 'firebase/auth'
import { ConfigMethods } from '/src/config.js'

const store = createStore({
    state: {
        user: {
            loggedIn: false,
            data: null
        },
        general: {
            data: null
        },
        branch: {
            data: null
        },
        category: {
            data: null
        },
        item: {
            data: null
        },
        rate: {
            data: null
        },
        reservation: {
            data: null
        }
    },

    getters: {
        user(state) {
            return state.user
        },
        general(state) {
            return state.general
        },
        branch(state) {
            return state.branch
        },
        category(state) {
            return state.category
        },
        item(state) {
            return state.item
        },
        rate(state) {
            return state.rate
        },
        reservation(state) {
            return state.reservation
        }
    },
    mutations: {
        SET_USER(state, payload) {
            state.user.data = payload
        },
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value
        },
        SET_GENERAL(state, data) {
            state.general.data = data
        },
        SET_BRANCH(state, data) {
            state.branch.data = data
        },
        SET_CATEGORY(state, data) {
            state.category.data = data
        },
        SET_ITEM(state, data) {
            state.item.data = data
        },
        SET_RATE(state, data) {
            state.rate.data = data
        },
        SET_RESERVATION(state, data) {
            state.reservation.data = data
        },
    },
    actions: {
        async register(context, { email, password, name }) {
            const { user } = await createUserWithEmailAndPassword(auth, email, password)
            if (user) {
                updateProfile(user, {
                    displayName: name
                })

                await setDoc(doc(db, "Client", user.uid), {
                    chronology: {
                        activated: null,
                        created: Timestamp.fromDate(new Date()),
                    },
                    isActive: false,
                    legalInformation: {
                        address: "",
                        commercialName: "",
                        legalName: "",
                        logo: "",
                        taxId: "",
                        domain: "",
                        aboutus: {
                            en: ""
                        },
                        contactus:{
                            en: ""
                        },
                        privacy: {
                            en: ""
                        },
                        terms: {
                            en: ""
                        }
                    },
                    representative: {
                        name: name,
                        email: "example@cellforder.com",
                        genre: "m",
                        mobile: "",
                        photo: ""
                    }
                }).then(() => {
                    setDoc(doc(db, `Client/${user.uid}/General`, "Configuration"), {
                        language: [
                            {
                                iso: "en",
                                name: "Ingles",
                                isActive: true,
                                isVisible: true
                            },
                        ]
                    }).then(() => {
                        context.commit('SET_USER', null)
                        context.commit('SET_GENERAL', null)
                    }).catch((err) => console.log(err))
                }).catch((err) => console.log(err))
            } else {
                throw new Error('Unable to register user')
            }
        },

        async logIn(context, { email, password }) {
            const response = await signInWithEmailAndPassword(auth, email, password)
            if (response) {
                const docRef = doc(db, "Client", response.user.uid)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    let dataClient = docSnap.data()
                    if(dataClient.isActive) {
                        localStorage.setItem("client", response.user.uid)
                        ConfigMethods.MAIN_URL = `/Client/${response.user.uid}`
                        context.commit('SET_USER', dataClient)
                        
                        const docRef = doc(db, `${ConfigMethods.MAIN_URL}/General/`, "Configuration")
                        const docSnap = await getDoc(docRef)
                        if (docSnap.exists()) {
                            let dataGeneral = docSnap.data()
                            context.commit('SET_GENERAL', dataGeneral)
                        } else {
                            throw new Error('Login failed - No existe el idioma')
                        }
                    } else {
                        throw new Error('Login failed - No está activo')
                    }
                } else {
                    throw new Error('Login failed - No existe documento')
                }
            } else {
                throw new Error('Login failed - No existe el usuario')
            }
        },

        async logOut(context) {
            localStorage.removeItem("client")
            await signOut(auth)
            context.commit('SET_USER', null)
            context.commit('SET_GENERAL', null)
        },

        async fetchUser(context, user) {
            context.commit("SET_LOGGED_IN", user !== null)
            if (!user) {
                throw new Error('Login failed - No existe el usuario')
            } else {
                const docRef = doc(db, "Client", user.uid)
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    let dataClient = docSnap.data()
                    if(dataClient.isActive) {
                        localStorage.setItem("client", user.uid)
                        ConfigMethods.MAIN_URL = `/Client/${user.uid}`
                        context.commit('SET_USER', dataClient)

                        const docRef = doc(db, `${ConfigMethods.MAIN_URL}/General/`, "Configuration")
                        const docSnap = await getDoc(docRef)
                        if (docSnap.exists()) {
                            let dataGeneral = docSnap.data()
                            context.commit('SET_GENERAL', dataGeneral)
                        } else {
                            throw new Error('Login failed - No existe el idioma')
                        }
                    } else {
                        throw new Error('Login failed - No está activo el usuario')
                    }
                } else {
                    throw new Error('Login failed - No existe el usuario')
                }
            }
        },

        async addGeneral(context, { general }) {
            context.commit('SET_GENERAL', {
                general: general
            })
        },

        async addBranch(context, { id, logo, name, explanation, order, isActive, isVisible, isTransactionable }) {
            context.commit('SET_BRANCH', {
                id: id,
                logo: logo,
                name: name,
                explanation: explanation,
                order: order,
                isActive: isActive,
                isVisible: isVisible,
                isTransactionable: isTransactionable
            });
        },

        async deleteBranch(context) {
            context.commit('SET_BRANCH', null)
        },

        async addCategory(context, { id, name, order, isActive, isVisible }) {
            context.commit('SET_CATEGORY', {
                id: id,
                name: name,
                order: order,
                isActive: isActive,
                isVisible: isVisible
            })
        },

        async deleteCategory(context) {
            context.commit('SET_CATEGORY', null)
        },
        
        async addItem(context, { id, category, album, name, explanation, itinerary, inclusion, policy, order, isActive, isVisible }) {
            context.commit('SET_ITEM', {
                id: id,
                category: category,
                album: album,
                name: name,
                explanation: explanation,
                itinerary: itinerary,
                inclusion: inclusion,
                policy: policy,
                order: order,
                isActive: isActive,
                isVisible: isVisible
            });
        },

        async deleteItem(context) {
            context.commit('SET_ITEM', null)
        },

        async addRate(context, { id, fromDate, adult, child, infant, old }) {
            context.commit('SET_RATE', {
                id: id,
                fromDate: fromDate,
                adult: adult,
                child: child, 
                infant: infant,
                old: old
            })
        },

        async deleteRate(context) {
            context.commit('SET_RATE', null)
        },

        async addReservation(context, { reservation }) {
            context.commit('SET_RESERVATION', reservation)
        },

        async deleteReservation(context) {
            context.commit('SET_RESERVATION', null)
        },
    }


})

// export the store
export default store