import { initializeApp } from "firebase/app"
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyB7wBQhdanRsNj9h8GVBgk0u8BRvnd92zY",
    authDomain: "cellf-order.firebaseapp.com",
    projectId: "cellf-order",
    storageBucket: "cellf-order.appspot.com",
    messagingSenderId: "263762741904",
    appId: "1:263762741904:web:a8104dbf47678cdf5c6615",
    measurementId: "G-8KS6KE9NXW"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth()
const db = getFirestore(app)
const storage = getStorage(app)

export { app, auth, db, storage }