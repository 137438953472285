<template>
    <form ref="form" @submit.prevent="submitForm">
        <div class="mb-3">
            <label for="profileName">Name</label>
            <input v-model="profileForm.name" type="text" class="form-control" id="profileName">
        </div>
        <div class="mb-3">
            <label for="profileEmail">Email</label>
            <input v-model="profileForm.email" type="email" class="form-control" id="profileEmail">
        </div>
        <div class="mb-3">
            <label for="profileMobile">Mobile</label>
            <input v-model="profileForm.mobile" type="tel" class="form-control" id="profileMobile">
        </div>
        <div class="mb-3">
            <label for="profileGenre">Genre</label>
            <select v-model="profileForm.genre" class="form-select" name="profileGenre" id="profileGenre">
                <option value="m">Male</option>
                <option value="f">Female</option>
            </select>
        </div>
        <div class="input-group mb-3">
            <input @change="handleFileChange" type="file" accept="image/*" class="form-control" id="inputGroupFile" aria-describedby="inputGroupFile" aria-label="Upload">
        </div>
        <div class="mb-3">
            <div class="upload__img-wrap">
                <div class="upload__img-box" v-for="(image, index) in images" :key="index">
                    <div :style="`background-image: url('${image.url}')`" class="img-bg">
                        <div @click="deleteImage(image, index)" class="upload__img-close"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-end">
                <button type="submit" class="btn btn-success">Save</button>
            </div>
        </div>
    </form>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue"
import { useStore } from "vuex"
import { useNotification } from "@kyvg/vue3-notification";
import { db, storage } from '../../firebaseConfig'
import { doc, updateDoc } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const { notify }  = useNotification()
        const multipleImages = ref(false)
        const images = ref([])
        const deletedImages = ref([])
        const files = ref([])
        const urls = ref([])

        const $loading = useLoading({
            color: '#539BFF'
        })

        const profileForm = computed(() => {
            return (store.getters.user.data) ? store.getters.user.data.representative : []
        })

        const deleteImage = (file, index) => {
            if (isURL(file.url)) {
                deletedImages.value.push(file)
            } else {
                for (var i = 0; i < files.value.length; i++) {
                    if (files.value[i].name === file.name) {
                        files.value.splice(i, 1);
                        break;
                    }
                }
            }

            images.value.splice(index, 1);
        }
        
        const submitForm = async () => {
            loader = $loading.show()
            uploadImagesInStorage()
            .then(() => {
                addToCollection()
                .then(() => {
                    deleteImagesInStorage()
                    .then(() => {
                        deletedImages.value = []
                        files.value = []
                        urls.value = []
                        document.getElementById('inputGroupFile').value= null
                        loader.hide()
                    })
                })
            })
        }

        const handleFileChange = (e) => {
            if (e.target.files) {
                if( !multipleImages.value ) {
                    if(images.value.length > 0) {
                        deleteImage(images.value[0], 0)
                    }
                    images.value = []
                    files.value = []
                }

                var reader  = new FileReader()
                for (const file of e.target.files) {
                    files.value.push(file)
                    reader.onloadend = function () {
                        let object = {
                            name: file.name,
                            url: reader.result
                        }
                        images.value.push(object)
                    }
                    reader.readAsDataURL(file)
                }
            }
        }

        async function deleteImagesInStorage() {
            const promises = []
            const deleteFile = async (storageReference) => {
                await deleteObject(storageReference)
            }
            
            try {
                deletedImages.value.forEach((file) => {
                    const storageReference = storageRef(storage, file.url)
                    promises.push(deleteFile(storageReference))
                })

                await Promise.all(promises)
            } catch (error) {
                console.log(error);
            }
        }

        function getRandomFileName() {
            var timestamp = new Date().toISOString().replace(/[-:.]/g,"")
            var random = ("" + Math.random()).substring(2, 8);
            var random_number = timestamp+random
            return random_number
        }

        async function uploadImagesInStorage() {
            const promises = []
            const uploadFile = async (storageReference, file) => {
                await uploadBytes(storageReference, file)
                await getDownloadURL(storageReference)
                .then((url) => {
                    urls.value.push(url)
                })
            }

            try {
                files.value.forEach((file) => {
                    let name = getRandomFileName()
                    const storageReference = storageRef(storage, 'profile/' + name)
                    promises.push(uploadFile(storageReference, file))
                })
            } catch (error) {
                console.log(error);
            }
            
            await Promise.all(promises)
        }

        async function addToCollection() {
            if(urls.value.length == 0 && deletedImages.value.length == 0) {
                if(images.value.length > 0) {
                    profileForm.value.photo = images.value[0].url
                } else {
                    profileForm.value.photo = ""    
                }
            } else if(urls.value.length == 0 && deletedImages.value.length > 0) {
                profileForm.value.photo = ""
            } else {
                profileForm.value.photo = urls.value[0]
                let object = {
                    name: urls.value[0],
                    url: urls.value[0]
                }
                images.value[0] = object
            }

            let client = localStorage.getItem('client')
            updateDoc(doc(db, `Client`, client), {
                representative: profileForm.value
            }).then(() => {
                notify({
                    type: "success",
                    title: "It was updated correctly"
                })
            })
        }

        function initImages() {
            if(profileForm.value.photo != "" && profileForm.value.photo != null && profileForm.value.photo != undefined) {
                let object = {
                    name: profileForm.value.photo,
                    url: profileForm.value.photo
                }
                images.value.push(object)
            }
        }

        function isURL(string) {
            let url
            try {
                url = new URL(string)
            } catch (_) {
                return false;  
            }

            return url.protocol === "http:" || url.protocol === "https:";
        }

        watch(() => profileForm.value,
            async () => {
                initImages()
            }
        )

        onMounted(async () => {
            initImages()
        })

        return { profileForm, images, submitForm, handleFileChange, deleteImage  }
    },
    components: {
        
    }
}
</script>