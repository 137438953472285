<template>
    <div
        class="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center justify-content-center w-100">
            <div class="row justify-content-center w-100">
                <div class="col-md-8 col-lg-6 col-xxl-3">
                    <div class="card mb-0">
                        <div class="card-body">
                            <a href="./index.html" class="text-nowrap logo-img text-center d-block py-3 w-100">
                                <img src="../assets/images/logos/dark-logo.svg" width="180" alt="">
                            </a>
                            <p class="text-center">CellF Order</p>
                            <Login v-if="isLogin" />
                            <Register v-if="!isLogin" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import { computed } from "vue";
// import { auth } from '../firebaseConfig'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'

export default {
    // name: "LoginComponent",
    computed: {
        isLogin() {
            return this.$route.name === 'Login'
        }
    },
    // setup() {
    //     const store = useStore()
    //     const router = useRouter()

    //     auth.onAuthStateChanged(user => {
    //         store.dispatch("fetchUser", user);
    //     });

    //     const user = computed(() => {
    //         return store.getters.user;
    //     });

    //     const signOut = async () => {
    //         await store.dispatch('logOut')
    //         router.push('/')
    //     }

    //     return { user, signOut }
    // },
    components: {
        Login,
        Register
    }
};
</script>