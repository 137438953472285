<template>
    <div class="row">
        <div class="col-12">
            <router-link class="btn btn-light-primary text-primary fs-3 mb-3" to="/branch/item/">
                <i class="ti ti-chevron-left"></i>
            </router-link>
        </div>
    </div>
        
    <div class="row">
        <p v-if="errors.length" class="alert alert-danger">
            <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
            <ul class="m-0 list-group">
                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
        </p>
        <form ref="form" @submit.prevent="submitForm" novalidate>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="false">Name</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="true">Overview</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="simple-tab-2" data-bs-toggle="tab" href="#simple-tabpanel-2" role="tab" aria-controls="simple-tabpanel-2" aria-selected="true">Itinerary</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="simple-tab-3" data-bs-toggle="tab" href="#simple-tabpanel-3" role="tab" aria-controls="simple-tabpanel-3" aria-selected="true">Inclusions & Exclusion</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="simple-tab-4" data-bs-toggle="tab" href="#simple-tabpanel-4" role="tab" aria-controls="simple-tabpanel-4" aria-selected="true">Policy</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="simple-tab-5" data-bs-toggle="tab" href="#simple-tabpanel-5" role="tab" aria-controls="simple-tabpanel-5" aria-selected="false">Gallery</a>
                </li>
            </ul>
            <div class="tab-content pt-5" id="tab-content">
                <div class="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">
                    <div class="mb-3">
                        <label for="menuCategory">Category</label>
                        <select class="form-select" name="menuCategory" id="menuCategory" v-model="itemForm.category">
                            <option value="null" disabled>Select option</option>
                            <option v-for="(category) in categories" :key="category.id" :value="category.id">
                                {{ category.name.en }} - {{ category.name.es }}
                            </option>
                        </select>
                    </div>
                    <div class="row" v-if="languages">
                        <div class="col-6" v-for="(language, index) in languages" :key="index">
                            <div class="input-group mb-3">
                                <span class="input-group-text text-uppercase" :id="`menuName${capitalize(language.iso)}`">{{ language.iso }}</span>
                                <input v-model="itemForm.name[language.iso]" type="text" class="form-control" :id="`menuName${capitalize(language.iso)}`" :aria-describedby="`menuName${capitalize(language.iso)}`">
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="menuOrder">Order</label>
                        <select v-model="itemForm.order" class="form-select" name="menuOrder" id="menuOrder">
                            <option value="null" disabled>Select option</option>
                            <option v-for="i in 20" :key="i" :value="i">{{ i }}</option>
                        </select>
                    </div>
                    <div class="mb-3 form-check">
                        <input v-model="itemForm.isActive" type="checkbox" class="form-check-input" id="menuIsActive">
                        <label class="form-check-label" for="menuIsActive">Is Active</label>
                    </div>
                    <div class="mb-3 form-check">
                        <input v-model="itemForm.isVisible" type="checkbox" class="form-check-input" id="menuIsVisible">
                        <label class="form-check-label" for="menuIsVisible">Is Visible</label>
                    </div>
                </div>
                <div class="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">
                    <div class="row" v-if="languages">
                        <div class="col-12" v-for="(language, index) in languages" :key="index">
                            <div class="mb-3">
                                <label class="text-uppercase" :for="`menuExplanation${capitalize(language.iso)}`">{{ language.iso }}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <editor v-model="itemForm.explanation[language.iso]" :id="`menuExplanation${capitalize(language.iso)}`"
                                    api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="simple-tabpanel-2" role="tabpanel" aria-labelledby="simple-tab-2">
                    <div class="row" v-if="languages">
                        <div class="col-12" v-for="(language, index) in languages" :key="index">
                            <div class="mb-3">
                                <label class="text-uppercase" :for="`menuItinerary${capitalize(language.iso)}`">{{ language.iso }}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <editor v-model="itemForm.itinerary[language.iso]" :id="`menuItinerary${capitalize(language.iso)}`"
                                    api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="simple-tabpanel-3" role="tabpanel" aria-labelledby="simple-tab-3">
                    <div class="row" v-if="languages">
                        <div class="col-12" v-for="(language, index) in languages" :key="index">
                            <div class="mb-3">
                                <label class="text-uppercase" :for="`menuInclusion${capitalize(language.iso)}`">{{ language.iso }}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <editor v-model="itemForm.inclusion[language.iso]" :id="`menuInclusion${capitalize(language.iso)}`"
                                    api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="simple-tabpanel-4" role="tabpanel" aria-labelledby="simple-tab-4">
                    <div class="row" v-if="languages">
                        <div class="col-12" v-for="(language, index) in languages" :key="index">
                            <div class="mb-3">
                                <label class="text-uppercase" :for="`menuPolicy${capitalize(language.iso)}`">{{ language.iso }}&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <editor v-model="itemForm.policy[language.iso]" :id="`menuPolicy${capitalize(language.iso)}`"
                                    api-key="6uv2ah3d5hqqtr5raox98pd8pxz8bgk2guh5kvuxulqpu9bh" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="simple-tabpanel-5" role="tabpanel" aria-labelledby="simple-tab-5">
                    <div class="mb-3">
                        <input @change="handleFileChange" type="file" accept="image/*" class="form-control" id="inputGroupFile" aria-describedby="inputGroupFile" aria-label="Upload" multiple>
                    </div>
                    <div class="mb-3">
                        <div class="upload__img-wrap">
                            <div class="upload__img-box" v-for="(image, index) in images" :key="index">
                                <div :style="`background-image: url(${image.url})`" class="img-bg">
                                    <div @click="deleteImage(image, index)" class="upload__img-close"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-end">
                    <button type="submit" class="btn btn-success">Save</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { ref, onMounted, reactive, computed } from "vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { useNotification } from "@kyvg/vue3-notification";
import { db, storage } from '../../firebaseConfig'
import { doc, collection, addDoc, updateDoc, query, orderBy, onSnapshot } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import Editor from '@tinymce/tinymce-vue'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const router = useRouter()
        const { notify }  = useNotification()
        const categories = ref([])
        const isEdit = ref(false)
        var id = null
        const multipleImages = ref(true)
        const images = ref([])
        const deletedImages = ref([])
        const files = ref([])
        const urls = ref([])
        const errors = ref([])

        const $loading = useLoading({
            color: '#539BFF'
        })
        
        const formInitialState = {
            album: [],
            category: null,
            name: {
                
            },
            explanation: {
                
            },
            itinerary: {
                
            },
            inclusion: {
                
            },
            policy: {
                
            },
            order: 0,
            isActive: false,
            isVisible: false
        }
        const itemForm = reactive({ ...formInitialState })

        const handleFileChange = (e) => {
            if (e.target.files) {
                if( !multipleImages.value ) {
                    if(images.value.length > 0) {
                        deleteImage(images.value[0], 0)
                    }
                    images.value = []
                    files.value = []
                }

                for (const file of e.target.files) {
                    let reader  = new FileReader()
                    files.value.push(file)
                    reader.onloadend = function () {
                        let object = {
                            name: file.name,
                            url: reader.result
                        }
                        images.value.push(object)
                    }
                    reader.readAsDataURL(file)
                }
            }
        }

        const deleteImage = (file, index) => {
            if (isURL(file.url)) {
                deletedImages.value.push(file.url)
            } else {
                for (var i = 0; i < files.value.length; i++) {
                    if (files.value[i].name === file.name) {
                        files.value.splice(i, 1)
                        break
                    }
                }
            }

            images.value.splice(index, 1)
        }

        const submitForm = async () => {
            if( isValidForm() ) {
                loader = $loading.show()
                uploadImagesInStorage()
                .then(() => {
                    addToCollection()
                    .then(() => {
                        loader.hide()
                        deleteImagesInStorage()
                    })
                })
            }
        }

        const languages = computed(() => {
            return (store.getters.general.data) ? store.getters.general.data.language : []
        })

        function capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        function isValidForm() {
            let error = false

            languages.value.forEach((language) => {
                if (!itemForm.name[language.iso]) {
                    errors.value.push(`Name ${capitalize(language.iso)} can't be empty`)
                    error = true
                }
            })

            return !error
        }

        function initForm() {
            Object.assign(itemForm, store.getters.item.data)
            itemForm.category = itemForm.category.id
            initImages()
        }

        function initImages() {
            if(itemForm.album.length > 0 && itemForm.album != null && itemForm.album != undefined) {
                for (var i = 0; i < itemForm.album.length; i++) {
                    let object = {
                        name: itemForm.album[i],
                        url: itemForm.album[i]
                    }
                    images.value.push(object)
                }
            }
        }

        function isURL(string) {
            let url
            try {
                url = new URL(string)
            } catch (_) {
                return false;  
            }

            return url.protocol === "http:" || url.protocol === "https:";
        }

        function getRandomFileName() {
            var timestamp = new Date().toISOString().replace(/[-:.]/g,"")
            var random = ("" + Math.random()).substring(2, 8);
            var random_number = timestamp+random
            return random_number
        }

        function deleteImagesInStorage() {
            const deleteFile = async (storageReference) => {
                deleteObject(storageReference)
            }

            try {
                deletedImages.value.forEach((file) => {
                    const storageReference = storageRef(storage, file)
                    deleteFile(storageReference)
                })
            } catch (error) {
                console.log(error);
            }
        }

        async function uploadImagesInStorage() {
            const promises = []
            const uploadFile = async (storageReference, file) => {
                await uploadBytes(storageReference, file)
                await getDownloadURL(storageReference)
                .then((url) => {
                    urls.value.push(url)
                })
            }

            try {
                files.value.forEach((file) => {
                    // const { name } = file
                    let name = getRandomFileName()
                    const storageReference = storageRef(storage, 'menu/' + name)
                    promises.push(uploadFile(storageReference, file))
                })
            } catch (error) {
                console.log(error);
            }
            
            await Promise.all(promises)
        }

        async function addToCollection() {
            if(urls.value.length == 0) {
                if(images.value.length > 0) {
                    itemForm.album = []
                    images.value.forEach((image) => {
                        itemForm.album.push(image.url)
                    })

                } else {
                    itemForm.album = []
                }
            }   else {
                itemForm.album = urls
                images.value.forEach((image) => {
                    if (isURL(image.url)) {
                        itemForm.album.push(image.url)
                    }
                })
            }
            
            if(isEdit.value) {
                let docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${id}/Menu/${itemForm.id}`)
                let categoryDocRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${id}/Category/${itemForm.category}`)
                itemForm.category = categoryDocRef
                
                delete itemForm.id

                await updateDoc(docRef, itemForm)
                .then(() => {
                    notify({
                        type: "success",
                        title: "It was updated correctly"
                    })
                    router.push('/branch/item/')
                })
                .catch((err) => console.log(err));
            } else {
                let categoryDocRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${id}/Category/${itemForm.category}`)
                itemForm.category = categoryDocRef

                await addDoc(collection(db, `${ConfigMethods.MAIN_URL}/Branch/${id}/Menu`), itemForm)
                .then((docRef) => {
                    console.log(docRef.id)
                    if (docRef.id) {
                        notify({
                            type: "success",
                            title: "It was saved correctly"
                        })
                        router.push('/branch/item/')
                    }
                })
                .catch((err) => console.log(err));
            }
        }

        function loadData() {
            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/Branch/${store.getters.branch.data.id}/Category`), orderBy('order'))
            onSnapshot(q, (querySnapshot) => {
                categories.value = []
                querySnapshot.forEach((doc) => {
                    categories.value.push({ id: doc.id, ...doc.data() })
                });
            });
        }

        onMounted(async () => {
            if( store.getters.branch.data == null ) {
                router.push('/branch/item/')
            } else {
                id = store.getters.branch.data.id
                loadData()
                if( store.getters.item.data != null ) {
                    initForm()
                    isEdit.value = true
                } else {
                    itemForm.order = 1
                }
            }
        });

        return { categories, itemForm, images, errors, languages, capitalize, handleFileChange, deleteImage, submitForm };
    },
    components: {
        'editor': Editor
    }
}
</script>