<template>
    <div class="row">
        <div class="col-12 mb-3">
            <button type="button" class="btn btn-primary mx-1" @click="addRow()">New Language</button>
        </div>
    </div>
    <form ref="form" @submit.prevent="submitForm" novalidate>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">ISO</th>
                    <th scope="col">Active</th>
                    <th scope="col">Visible</th>
                    <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(language, index) in languageForm" :key="index">
                    <td>
                        <input v-model="language.name" :disabled="index == 0" type="text" class="form-control"
                            :id="`languageName${index}`">
                    </td>
                    <td>
                        <input v-model="language.iso" :disabled="index == 0 || !('isNewRow' in language)" type="text"
                            class="form-control" :id="`languageIso${index}`">
                    </td>
                    <td class="align-middle">
                        <input v-model="language.isActive" :disabled="index == 0" type="checkbox"
                            class="form-check-input" :id="`languageIsActive${index}`">
                    </td>
                    <td class="align-middle">
                        <input v-model="language.isVisible" :disabled="index == 0" type="checkbox"
                            class="form-check-input" :id="`languageIsVisible${index}`">
                    </td>
                    <td class="align-middle fs-6">
                        <a href="javascript:void(0)" v-show="index != 0" v-tooltip="'Delete'" data-bs-toggle="modal"
                            data-bs-target="#exampleModal" @click="setIdToDelete(index)">
                            <i class="ti ti-trash"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="row">
            <div class="col-12 text-end">
                <button type="submit" class="btn btn-success">Save</button>
            </div>
        </div>
    </form>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure about deleting the information?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-danger" data-bs-dismiss="modal" @click="removeRow()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { computed, onMounted } from "vue"
import { onBeforeRouteLeave } from "vue-router"
import { useStore } from "vuex"
import { useNotification } from "@kyvg/vue3-notification"
import { db } from '../../firebaseConfig'
import { doc, getDoc, getDocs, query, updateDoc, collection } from 'firebase/firestore'
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        var idToDelete = null
        const store = useStore()
        // const router = useRouter()
        const { notify } = useNotification()
        var languageToDeleted = []

        const $loading = useLoading({
            color: '#539BFF'
        })

        const formInitialState = {
            name: '',
            iso: '',
            isActive: false,
            isVisible: false,
            isNewRow: true
        }

        const addRow = () => {
            languageForm.value.push({ ...formInitialState })
        }

        function setIdToDelete(id) {
            idToDelete = id
        }

        const removeRow = () => {
            if (languageForm.value[idToDelete].iso && !(languageForm.value[idToDelete].isNewRow)) {
                languageToDeleted.push(languageForm.value[idToDelete].iso)
            }

            languageForm.value.splice(idToDelete, 1)
            idToDelete = null
        }
        
        const submitForm = async () => {
            loader = $loading.show()

            if (languageToDeleted.length > 0) {
                const updateDocument = async (docRef, data) => {
                    await updateDoc(docRef, data)
                }

                const getDocuments = async (docRef) => {
                    const querySnapshot = await getDocs(docRef)
                    return querySnapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id
                        }
                    })
                }

                const docRef = doc(db, `${ConfigMethods.MAIN_URL}/General`, 'Site')
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    let data = docSnap.data()
                    languageToDeleted.forEach((isoToDelete) => {
                        delete data.aboutus[isoToDelete]
                        delete data.contactus[isoToDelete]
                        delete data.privacy[isoToDelete]
                        delete data.terms[isoToDelete]
                    })
                    updateDocument(docRef, { aboutus: data.aboutus, contactus: data.contactus, privacy: data.privacy, terms: data.terms })
                    .then(() => {
                        const queryBranch = query(collection(db, `${ConfigMethods.MAIN_URL}/Branch`))
                        getDocuments(queryBranch)
                        .then((docsBranch) => {
                            let countBranches = 0
                            docsBranch.forEach((docBranch) => {
                                languageToDeleted.forEach((isoToDelete) => {
                                    delete docBranch.name[isoToDelete]
                                    delete docBranch.explanation[isoToDelete]
                                })

                                const docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch`, docBranch.id)
                                updateDocument(docRef, { name: docBranch.name, explanation: docBranch.explanation })
                                .then(() => {
                                    const queryCategory = query(collection(db, `${ConfigMethods.MAIN_URL}/Branch/${docBranch.id}/Category`))
                                    getDocuments(queryCategory)
                                    .then((docsCategory) => {
                                        docsCategory.forEach((docCategory) => {
                                            languageToDeleted.forEach((isoToDelete) => {
                                                delete docCategory.name[isoToDelete]
                                            })

                                            const docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${docBranch.id}/Category`, docCategory.id)
                                            updateDocument(docRef, { name: docCategory.name })
                                            .then(() => {
                                                const queryMenu = query(collection(db, `${ConfigMethods.MAIN_URL}/Branch/${docBranch.id}/Menu`))
                                                getDocuments(queryMenu)
                                                .then((docsMenu) => {
                                                    docsMenu.forEach((docMenu) => {
                                                        languageToDeleted.forEach((isoToDelete) => {
                                                            delete docMenu.name[isoToDelete]
                                                            delete docMenu.explanation[isoToDelete]
                                                            delete docMenu.itinerary[isoToDelete]
                                                            delete docMenu.inclusion[isoToDelete]
                                                            delete docMenu.policy[isoToDelete]
                                                        })

                                                        const docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${docBranch.id}/Menu`, docMenu.id)
                                                        updateDocument(docRef, { name: docMenu.name, explanation: docMenu.explanation, itinerary: docMenu.itinerary, inclusion: docMenu.inclusion, policy: docMenu.policy })
                                                        .then(() => {
                                                            countBranches++

                                                            if ((countBranches == docsBranch.length) && removeEmptyInputs()) {
                                                                updateDoc(doc(db, `${ConfigMethods.MAIN_URL}/General/`, 'Configuration'), {
                                                                    language: languageForm.value
                                                                }).then(() => {
                                                                    
                                                                    // if(countBranches == docsBranch.length) {
                                                                        loader.hide()
                                                                        notify({
                                                                            type: "success",
                                                                            title: "It was updated correctly"
                                                                        })
                                                                    // }
                                                                })
                                                            }

                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                }
            } else {
                if (removeEmptyInputs()) {
                    await updateDoc(doc(db, `${ConfigMethods.MAIN_URL}/General/`, "Configuration"), {
                        language: languageForm.value
                    }).then(() => {
                        loader.hide()
                        notify({
                            type: "success",
                            title: "It was updated correctly"
                        })
                        // router.push('/branch/')
                    })
                }
            }
        }

        const languageForm = computed(() => {
            return (store.getters.general.data) ? store.getters.general.data.language : []
        })

        function capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        function removeEmptyInputs() {
            let languageToDelete = []
            languageForm.value.forEach((language, index) => {
                if (!language.name || !language.iso) {
                    languageToDelete.push(index)
                }
                if ('isNewRow' in language) {
                    delete language['isNewRow']
                }
            })

            languageToDelete.forEach((index) => {
                languageForm.value.splice(index, 1)
            })

            return true
        }

        onMounted(async () => {

        })

        onBeforeRouteLeave(() => {
            removeEmptyInputs()
        })

        return { languageForm, capitalize, submitForm, addRow, setIdToDelete, removeRow }
    },
    components: {

    }
}

</script>