<template>
    <div class="row">
        <div class="col-12 text-right">
            <router-link class="btn btn-light-primary text-primary fs-3" to="/branch/item/">
                <i class="ti ti-chevron-left"></i>
            </router-link>
            <router-link class="btn btn-primary mx-1" to="/branch/item/rate/new">New Rate</router-link>
        </div>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">From Date</th>
                <th scope="col" class="text-end">Adult</th>
                <th scope="col" class="text-end">Child</th>
                <th scope="col" class="text-end">Infant</th>
                <th scope="col" class="text-end">Old</th>
                <th scope="col" class="text-center">Edit</th>
                <th scope="col" class="text-center">Delete</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="rate in rates" :key="rate.id">
                <td class="align-middle fs-4">{{ rate.fromDate.toDate().toLocaleDateString() }}</td>
                <td class="text-end align-middle fs-4">{{ rate.adult.price }}</td>
                <td class="text-end align-middle fs-4">{{ rate.child.price }}</td>
                <td class="text-end align-middle fs-4">{{ rate.infant.price }}</td>
                <td class="text-end align-middle fs-4">{{ rate.old.price }}</td>
                <td class="text-center align-middle fs-6">
                    <a href="javascript:void(0)" @click.prevent="edit(rate.id)" v-tooltip="'Edit'">
                        <i class="ti ti-edit"></i>
                    </a>
                </td>
                <td class="text-center align-middle fs-6">
                    <a href="javascript:void(0)" v-tooltip="'Delete'" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="setIdToDelete(rate.id)">
                        <i class="ti ti-trash"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                Are you sure about deleting the information?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-danger" data-bs-dismiss="modal" @click="deleteRate()">Yes</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ConfigMethods } from '/src/config.js'
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'
import { useNotification } from "@kyvg/vue3-notification"
import { db } from '../../firebaseConfig'
import { collection, query, onSnapshot, doc, deleteDoc } from "firebase/firestore"
import { useLoading } from 'vue-loading-overlay'

export default {
    setup() {
        var loader = null
        var idToDelete = null
        const router = useRouter()
        const store = useStore()
        const { notify }  = useNotification()
        const rates = ref([])

        const $loading = useLoading({
            color: '#539BFF'
        })

        function setIdToDelete(id) {
            idToDelete = id
        }

        async function deleteRate() {
            loader = $loading.show()
            const docRef = doc(db, `${ConfigMethods.MAIN_URL}/Branch/${store.getters.branch.data.id}/Menu/${store.getters.item.data.id}/Rate`, idToDelete);
            deleteDoc(docRef)
            .then(() => {
                loader.hide()
                notify({
                    type: "success",
                    title: "It was deleted correctly"
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
        
        async function deleteStore() {
            await store.dispatch('deleteRate')
        }

        const edit = (id) => {
            saveRate(id)
            router.push('/branch/item/rate/edit')
        }

        async function saveRate(id) {
            let data = rates.value.find(x => x.id === id)
            await store.dispatch('addRate', data)
        }

        function loadData() {
            const q = query(collection(db, `${ConfigMethods.MAIN_URL}/Branch/${store.getters.branch.data.id}/Menu/${store.getters.item.data.id}/Rate`))
            onSnapshot(q, (querySnapshot) => {
                rates.value = []
                querySnapshot.forEach((doc) => {
                    rates.value.push({ id: doc.id, ...doc.data() })
                })
            })
        }
        
        onMounted(() => {
            if( store.getters.branch.data == null ) {
                router.push('/branch/')
            } else {
                loadData()
                deleteStore()
            }
        })

        return { rates, edit, setIdToDelete, deleteRate };
    }
}
</script>