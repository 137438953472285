<template>
    <form ref="form" @submit.prevent="submitForm">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="false">General</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="true">Logo</a>
            </li>
        </ul>
        <div class="tab-content pt-5" id="tab-content">
            <div class="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">
                <div class="mb-3">
                    <label for="companyDomain">Domain</label>
                    <input v-model="companyForm.domain" type="text" class="form-control" id="companyDomain">
                </div>
                <div class="mb-3">
                    <label for="companyCommercialName">Commercial Name</label>
                    <input v-model="companyForm.commercialName" type="text" class="form-control" id="companyCommercialName">
                </div>
                <div class="mb-3">
                    <label for="companyLegalName">Legal Name</label>
                    <input v-model="companyForm.legalName" type="text" class="form-control" id="companyLegalName">
                </div>
                <div class="mb-3">
                    <label for="companyTaxId">Tax ID</label>
                    <input v-model="companyForm.taxId" type="text" class="form-control" id="companyTaxId">
                </div>
                <div class="mb-3">
                    <label for="companyAddress">Address</label>
                    <input v-model="companyForm.address" type="text" class="form-control" id="companyAddress">
                </div>
            </div>
            <div class="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">
                <div>
                    <div id="imageHelp" class="form-text">Recommended size: 180x27 px. All types of image formats are accepted.</div>
                </div>
                <div class="input-group mb-3">
                    <input @change="handleFileChange" type="file" accept="image/*" class="form-control" id="inputGroupFile" aria-describedby="inputGroupFile" aria-label="Upload">
                </div>
                <div class="mb-3">
                    <div class="upload__img-wrap">
                        <div class="upload__img-box" v-for="(image, index) in images" :key="index">
                            <div :style="`background-image: url('${image.url}')`" class="img-bg">
                                <div @click="deleteImage(image, index)" class="upload__img-close"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-end">
                <button type="submit" class="btn btn-success">Save</button>
            </div>
        </div>
    </form>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue"
import { useStore } from "vuex"
import { useNotification } from "@kyvg/vue3-notification";
import { db, storage } from '../../firebaseConfig'
import { doc, updateDoc } from 'firebase/firestore'
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { useLoading } from 'vue-loading-overlay'
// import Editor from '@tinymce/tinymce-vue'

export default {
    setup() {
        var loader = null
        const store = useStore()
        const { notify }  = useNotification()
        const multipleImages = ref(false)
        const images = ref([])
        const deletedImages = ref([])
        const files = ref([])
        const urls = ref([])

        const $loading = useLoading({
            color: '#539BFF'
        })

        const languages = computed(() => {
            return (store.getters.general.data) ? store.getters.general.data.language : []
        })

        const companyForm = computed(() => {
            return (store.getters.user.data) ? store.getters.user.data.legalInformation : []
        })

        function capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        const deleteImage = (file, index) => {
            if (isURL(file.url)) {
                deletedImages.value.push(file)
            } else {
                for (var i = 0; i < files.value.length; i++) {
                    if (files.value[i].name === file.name) {
                        files.value.splice(i, 1);
                        break;
                    }
                }
            }

            images.value.splice(index, 1);
        }
        
        const submitForm = async () => {
            loader = $loading.show()
            uploadImagesInStorage()
            .then(() => {
                addToCollection()
                .then(() => {
                    deleteImagesInStorage()
                    .then(() => {
                        deletedImages.value = []
                        files.value = []
                        urls.value = []
                        document.getElementById('inputGroupFile').value= null
                        loader.hide()
                    })
                })
            })
        }

        const handleFileChange = (e) => {
            if (e.target.files) {
                if( !multipleImages.value ) {
                    if(images.value.length > 0) {
                        deleteImage(images.value[0], 0)
                    }
                    images.value = []
                    files.value = []
                }

                var reader  = new FileReader()
                for (const file of e.target.files) {
                    files.value.push(file)
                    reader.onloadend = function () {
                        let object = {
                            name: file.name,
                            url: reader.result
                        }
                        images.value.push(object)
                    }
                    reader.readAsDataURL(file)
                }
            }
        }

        async function deleteImagesInStorage() {
            const promises = []
            const deleteFile = async (storageReference) => {
                await deleteObject(storageReference)
            }
            
            try {
                deletedImages.value.forEach((file) => {
                    const storageReference = storageRef(storage, file.url)
                    promises.push(deleteFile(storageReference))
                })

                await Promise.all(promises)
            } catch (error) {
                console.log(error);
            }
        }

        function getRandomFileName() {
            var timestamp = new Date().toISOString().replace(/[-:.]/g,"")
            var random = ("" + Math.random()).substring(2, 8);
            var random_number = timestamp+random
            return random_number
        }

        async function uploadImagesInStorage() {
            const promises = []
            const uploadFile = async (storageReference, file) => {
                await uploadBytes(storageReference, file)
                await getDownloadURL(storageReference)
                .then((url) => {
                    urls.value.push(url)
                })
            }

            try {
                files.value.forEach((file) => {
                    let name = getRandomFileName()
                    const storageReference = storageRef(storage, 'logo/' + name)
                    promises.push(uploadFile(storageReference, file))
                })
            } catch (error) {
                console.log(error);
            }
            
            await Promise.all(promises)
        }

        async function addToCollection() {
            if(urls.value.length == 0 && deletedImages.value.length == 0) {
                if(images.value.length > 0) {
                    companyForm.value.logo = images.value[0].url
                } else {
                    companyForm.value.logo = ""    
                }
            } else if(urls.value.length == 0 && deletedImages.value.length > 0) {
                companyForm.value.logo = ""
            } else {
                companyForm.value.logo = urls.value[0]
                let object = {
                    name: urls.value[0],
                    url: urls.value[0]
                }
                images.value[0] = object
            }

            let client = localStorage.getItem('client')
            updateDoc(doc(db, `Client`, client), {
                legalInformation: companyForm.value
            }).then(() => {
                notify({
                    type: "success",
                    title: "It was updated correctly"
                })
            })
        }

        function initImages() {
            if(companyForm.value.logo != "" && companyForm.value.logo != null && companyForm.value.logo != undefined) {
                let object = {
                    name: companyForm.value.logo,
                    url: companyForm.value.logo
                }
                images.value.push(object)
            }
        }

        function isURL(string) {
            let url
            try {
                url = new URL(string)
            } catch (_) {
                return false;  
            }

            return url.protocol === "http:" || url.protocol === "https:";
        }

        watch(() => companyForm.value,
            async () => {
                initImages()
            }
        )

        onMounted(async () => {
            initImages()
        })

        return { languages, companyForm, images, capitalize, submitForm, handleFileChange, deleteImage  }
    },
    components: {
        // 'editor': Editor
    }
}
</script>